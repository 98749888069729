// @ts-strict-ignore
import React, { useContext } from 'react';
import { useLocalObservable } from 'bb/app/stores';
import { type FCChildrenOnly } from 'bb/common/types';
import { campaignStore } from '../campaignStore';
import { type CampaignStore } from '../types';

export const StoreContext = React.createContext<CampaignStore | null>(null);

export const CampaignStoreProvider: FCChildrenOnly = ({ children }) => {
    const store = useLocalObservable(campaignStore);
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

export const useCampaignStore = (): CampaignStore => {
    const store = useContext<CampaignStore>(StoreContext);

    if (!store) {
        throw new Error('useCampaignStore must be used within StoreProvider');
    }
    return store;
};
