// @ts-strict-ignore
import { Debug } from 'bb/app/debug';
import { type CampaignStore } from './types';

const debug = Debug('campaignStore');

const initialState = {
    campaign: null,
    campaignCode: null,
    isWaitingForAdditionalData: false
};

export function campaignStore(): CampaignStore {
    return {
        ...initialState,
        setCampaignCode(code, data) {
            this.campaignCode = code;
            this.campaignFormValues = data;
        },
        setCampaign(campaign) {
            debug.info('setCampaign');
            this.campaign = {
                ...campaign,
                priceRule: campaign?.priceRules?.length
                    ? campaign.priceRules[0]
                    : null
            };
        },
        clearCampaign() {
            debug.info('clearCampaign');
            this.campaign = null;
            this.campaignFormValues = null;
            this.campaignCode = '';
        },
        setIsWaitingForAdditionalData(isWaitingForAdditionalData) {
            this.isWaitingForAdditionalData = isWaitingForAdditionalData;
        }
    };
}
